export default (iframe) => {
  window.addEventListener('message', (event) => {
    if (
      event.data &&
      event.data.event_name &&
      event.data.event_name === 'update:height'
    ) {
      // eslint-disable-next-line no-param-reassign
      iframe.height = `${event.data.value}px`
    }
  })
}
