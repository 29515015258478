import gsap from 'gsap'
// import loadScript from '~utils/loadScript'

export default () => ({
  stepIndex: 0,
  // animTo: null,
  async init() {
    // Wait for directive splitting to be loaded
    await this.$nextTick()

    const countItems = this.$refs.steps.children.length

    const tl = gsap.timeline({
      scrollTrigger: {
        refreshPriority: 2,
        trigger: this.$el,
        start: 'center center',
        end: 'center -200%',
        scrub: 1,
        ease: 'power3',
        pin: true,
        snap: {
          snapTo: 'labelsDirectional',
          duration: { min: 0.2, max: 1 },
        },
        fastScrollEnd: true,
        preventOverlaps: true,
        // onUpdate: ({ progress }) => {
        //   if (this.animTo) {
        //     this.animTo(progress)
        //   }
        // },
      },
    })

    tl.addLabel('start')
    tl.addPause(1)

    for (let stepIndex = 0; stepIndex < countItems; stepIndex += 1) {
      const label = `step${stepIndex}`
      const step = this.$refs[label]
      const { lines } = step.querySelector('p').$splitting
      const cta = step.querySelector('.b-cta')
      if (cta) lines.push(cta)

      tl.set(step, { autoAlpha: 1 })

      if (stepIndex > 0) {
        const linesEnter = gsap.timeline()
        lines.forEach((line, index) => {
          linesEnter.from(
            line,
            { autoAlpha: 0, y: 20, duration: 0.5, ease: 'power3.out' },
            index * 0.1
          )
        })
        tl.add(linesEnter)
      }

      tl.addLabel(label)

      if (stepIndex < countItems - 1) {
        const linesLeave = gsap.timeline()
        lines.forEach((line, index) => {
          linesLeave.to(
            line,
            { autoAlpha: 0, y: -20, duration: 0.5, ease: 'power3.in' },
            index * 0.1
          )
        })
        tl.add(linesLeave)
      }
    }

    // try {
    //   const key = 'data-animation-url'
    //   const path = this.$el.getAttribute(key)
    //   this.$el.removeAttribute(key)
    //   await loadScript(
    //     'https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.10.0/lottie_light.min.js'
    //   )
    //   const anim = await window.lottie.loadAnimation({
    //     container: this.$refs.animation,
    //     path,
    //     renderer: 'svg',
    //     loop: false,
    //     autoplay: false,
    //     name: 'One',
    //   })
    //   anim.addEventListener('DOMLoaded', () => {
    //     const svg = this.$refs.animation.children[0]
    //     svg.setAttribute('preserveAspectRatio', 'xMidYMid slice')
    //     const totalFrames = anim.totalFrames - 1 // last frame of the anim is blank, let's remove it
    //     const framesPerStep = [0, 40, 80, totalFrames - 20]
    //     const animData = {
    //       progress: 0,
    //       goTo: gsap.utils.pipe(
    //         gsap.utils.interpolate(framesPerStep.map((v) => v / totalFrames)),
    //         (animProgress) => animProgress * totalFrames,
    //         (frame) => anim.goToAndStop(frame, true)
    //       ),
    //     }

    //     this.animTo = gsap.quickTo(animData, 'progress', {
    //       duration: 2,
    //       ease: 'power2',
    //       onUpdate() {
    //         animData.goTo(animData.progress)
    //       },
    //     })
    //   })
    // } catch (error) {
    //   console.error('error while loading lottie', error)
    // }
  },
})
