export default () => ({
  index: null,
  toggle(index) {
    if (index === this.index) {
      this.index = null
    } else {
      this.index = index
    }
  },
})
