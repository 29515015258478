import gsap from 'gsap'

export default () => ({
  navOpen: false,
  langOpen: false,
  adminBarOffsetTimeline: null,
  canAnimate: false,
  init() {
    window.addEventListener('resize', this.onResize.bind(this))
    this.onResize()
    setTimeout(() => {
      this.canAnimate = true
    }, 500)
  },
  handleAdminBarOffset(dynamic = false) {
    const wpAdminBar = document.getElementById('wpadminbar')
    const height = wpAdminBar.offsetHeight

    this.$store.ui.setAdminBarHeight(height)

    if (dynamic) {
      if (this.adminBarOffsetTimeline) {
        return
      }
      this.adminBarOffsetTimeline = gsap
        .timeline({
          scrollTrigger: {
            start: 'top top',
            end: `+=${height}`,
            scrub: true,
          },
        })
        .fromTo(
          this.$el,
          { top: height },
          {
            top: 0,
            onUpdate: () => {
              this.$store.ui.setAdminBarHeight(parseFloat(this.$el.style.top))
            },
          }
        )
    } else {
      const key = 'adminBarOffsetTimeline'
      if (this[key]) {
        this[key].kill()
        this[key] = null
      }
      this.$el.style.top = `${height}px`
      this.$store.ui.setAdminBarHeight(height)
    }
  },
  onResize() {
    this.$store.ui.setHeaderHeight(
      window.matchMedia('(min-width: 1280px)').matches ? 80 : 60
    )
    if (this.$store.ui.hasAdminBar) {
      const { matches: dynamic } = window.matchMedia('(max-width: 600px)')
      this.handleAdminBarOffset(dynamic)
    }
  },
  onToggleMenu() {
    if (this.navOpen) {
      this.navOpen = false
      this.langOpen = false
    } else {
      this.navOpen = true
    }
  },
  destroy() {
    window.removeEventListener('resize', this.onResize)
    if (this.adminBarOffsetTimeline) {
      this.adminBarOffsetTimeline.kill()
    }
  },
})
