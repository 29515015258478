import gsap from 'gsap'

export default () => ({
  stepIndex: 0,
  steps: 0,
  active: false,
  timeline: null,
  animating: false,
  async init() {
    // Wait for directive splitting to be loaded
    await this.$nextTick()

    this.steps = this.$el.children.length

    if (this.steps > 1) {
      const tl = gsap.timeline({
        scrollTrigger: {
          refreshPriority: 2,
          trigger: this.$el,
          start: 'top top',
          end: `bottom -${this.steps * 50}%`,
          scrub: 1,
          ease: 'power3',
          pin: true,
          snap: {
            snapTo: 'labelsDirectional',
            duration: { min: 0.2, max: 1 },
          },
          fastScrollEnd: true,
          preventOverlaps: true,
          onToggle: ({ isActive }) => {
            this.$store.ui.setHeaderTransparent(isActive)
          },
        },
      })

      for (let stepIndex = 0; stepIndex < this.steps; stepIndex += 1) {
        const label = `step${stepIndex}`
        const step = this.$refs[label]
        const media = step.querySelector('img, video')
        const { lines } = step.querySelector('h1').$splitting
        const cta = step.querySelector('.b-cta')
        if (cta) lines.push(cta)

        tl.add(() => {
          // Once fully appeared, if there's no media then header should not be transparent
          if (!media) {
            this.$store.ui.setHeaderTransparent(
              tl.scrollTrigger.direction === -1
            )
          }
        })

        if (stepIndex > 0) {
          const linesEnter = gsap.timeline()
          lines.forEach((line, index) => {
            linesEnter.from(
              line,
              {
                autoAlpha: 0,
                y: 50,
                duration: 0.5,
                ease: 'power3.out',
              },
              index * 0.1
            )
          })
          tl.add(linesEnter)
        }

        tl.addLabel(label)

        if (stepIndex < this.steps - 1) {
          const linesLeave = gsap.timeline()
          lines.forEach((line, index) => {
            linesLeave.to(
              line,
              {
                autoAlpha: 0,
                y: -50,
                duration: 0.5,
                ease: 'power3.in',
              },
              index * 0.1
            )
          })
          tl.add(linesLeave)

          tl.to(
            step,
            { autoAlpha: 0, duration: 0.5, ease: 'power3.in' },
            '-=0.3'
          )
        }

        this.timeline = tl
      }
    } else {
      const step = this.$refs.step0
      gsap.set(step, { autoAlpha: 1 })
      const media = step.querySelector('img, video')
      if (media) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: 'top 1px',
            end: 'bottom 15%',
            onToggle: ({ isActive }) => {
              this.$store.ui.setHeaderTransparent(isActive)
            },
          },
        })

        this.timeline = tl
      }
    }
  },
})
