import gsap from 'gsap'

export default ({ bgColors }) => ({
  init() {
    const cards = Array.from(this.$el.querySelectorAll('.sticky-card'))

    if (cards.length >= 2) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top top',
          end: `bottom -${cards.length * 50}%`,
          scrub: 1,
          ease: 'power3',
          pin: true,
          anticipatePin: true,
          snap: {
            snapTo: 'labelsDirectional',
            duration: { min: 0.2, max: 1 },
          },
          fastScrollEnd: true,
          preventOverlaps: true,
        },
      })

      cards.forEach((card, index) => {
        const label = `card${index}`
        const title = card.querySelector('.card-title')
        const subtitle = card.querySelector('.card-subtitle')
        const body = card.querySelector('.card-body')

        if (index > 0) {
          const enter = gsap.timeline({ defaults: { ease: 'power3.out' } })
          enter
            .from(title, { autoAlpha: 0, y: 50, duration: 0.5 })
            .from(subtitle, { autoAlpha: 0, y: 50, duration: 0.5 }, '<0.1')
            .from(body, { autoAlpha: 0, y: 100, duration: 0.5 }, '<0.1')
          tl.add(enter)
        }

        tl.addLabel(label)

        if (index < cards.length - 1) {
          const leave = gsap.timeline({ defaults: { ease: 'power3.in' } })
          leave
            .to(title, { autoAlpha: 0, y: -50, duration: 0.5 })
            .to(subtitle, { autoAlpha: 0, y: -50, duration: 0.5 }, '<0.1')
            .to(body, { autoAlpha: 0, y: -100, duration: 0.5 }, '<0.1')
          tl.add(leave)
          tl.to(
            card,
            { autoAlpha: 0, duration: 0.5, ease: 'power3.in' },
            '-=0.3'
          )
          if (bgColors[index + 1]) {
            tl.to(
              this.$el,
              {
                backgroundColor: bgColors[index + 1],
                duration: 0.5,
                ease: 'power3.in',
              },
              '<'
            )
          }
        }
      })
    }
  },
  get paddingTop() {
    const { ui } = this.$store
    return `${ui.adminBarHeight + ui.headerHeight}px`
  },
})
