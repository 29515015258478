export default () => {
  function isTouch() {
    return !window.matchMedia('(hover: hover)').matches
  }

  return {
    isVisible: true,
    size: '',
    dragging: false,
    startX: 0,
    startY: 0,
    currentX: 0,
    currentY: 0,
    doubleTapTimeout: false,
    init() {
      this.$watch('dragging', this.onDraggingChange.bind(this))
      window.addEventListener('resize', this.updateSize.bind(this))
      this.updateSize()
    },
    onDraggingChange(value) {
      const method = value ? 'addEventListener' : 'removeEventListener'
      window[method](
        isTouch() ? 'touchmove' : 'mousemove',
        this.onDrag.bind(this)
      )
    },
    updateSize() {
      this.size = `${window.innerWidth}x${window.innerHeight}`
    },
    destroy() {
      this.$watch('dragging', this.onDraggingChange)
      window.removeEventListener('resize', this.updateSize)
    },
    onDragStart(event) {
      if (this.doubleTapTimeout) {
        this.isVisible = false
      } else {
        this.doubleTapTimeout = setTimeout(() => {
          this.doubleTapTimeout = null
        }, 300)
      }

      if (event.type === 'touchstart') {
        this.startX = event.touches[0].clientX - this.currentX
        this.startY = event.touches[0].clientY - this.currentY
      } else {
        this.startX = event.clientX - this.currentX
        this.startY = event.clientY - this.currentY
      }

      this.dragging = true
    },
    onDragEnd() {
      this.dragging = false
    },
    onDrag(event) {
      if (this.dragging) {
        if (event.type === 'touchmove') {
          this.currentX = event.touches[0].clientX - this.startX
          this.currentY = event.touches[0].clientY - this.startY
        } else {
          this.currentX = event.clientX - this.startX
          this.currentY = event.clientY - this.startY
        }
      }
    },
  }
}
