import Splide from '@splidejs/splide'
import { debounce } from 'lodash-es'

/**
 * Slider component
 * @param {Object} params
 * @param {'default'|'cards'} [params.mode] - The slider mode.
 * @param {boolean} [params.lgDestroy] - Whether to destroy the slider on large screens (e.g.: to display a grid instead).
 */
export default (params = {}) => {
  const mode = params.mode || 'default'
  const lgDestroy = params.lgDestroy || false
  let main = null
  let nav = null

  function updateNavSlider() {
    const currentSlide = nav.Components.Elements.slides[this.slideIndex]

    const track = nav.root.querySelector('.splide__track')
    const trackRect = track.getBoundingClientRect()
    const slideRect = currentSlide.getBoundingClientRect()

    this.navSliderX = `${Math.max(
      parseInt(track.style.paddingLeft, 10),
      slideRect.x - trackRect.x
    )}px`
    this.navSliderWidth = slideRect.width
  }

  // Set the height of the text over the media to its height
  function updateTextOverInSlides() {
    main.Components.Elements.slides.forEach((slide) => {
      const text = slide.querySelector('.b-text--over')
      if (slide.children.length > 2 && text) {
        const media = slide.querySelector('.b-media')
        text.style.height = `${media.offsetHeight}px`
      }
    })
  }

  const onResize = debounce(function r() {
    updateNavSlider.call(this)
    updateTextOverInSlides.call(this)
  }, 300)

  function onActiveChange(event) {
    this.slideIndex = event.index
    updateNavSlider.call(this)
  }

  return {
    slideIndex: 0,
    navSliderX: 0,
    navSliderWidth: 0,
    init() {
      main = new Splide(this.$refs.main, {
        arrows: false,
        pagination: false,
        gap: mode === 'cards' ? '0.5rem' : 0,
        mediaQuery: 'min',
        breakpoints: {
          1024: {
            gap: 0,
            destroy: lgDestroy,
          },
        },
      })
      nav = new Splide(this.$refs.nav, {
        arrowPath:
          'M16.281 12.4881L17.1892 11.512L26.312 20L17.1892 28.4881L16.281 27.512L24.3533 20L16.281 12.4881Z',
        classes: {
          arrows: '',
          arrow: [
            'rounded-full bg-stone-200 disabled:opacity-50',
            '[&>svg]:w-[1.875rem] [&>svg]:h-[1.875rem] [&>svg]:fill-current',
            'transition',
          ].join(' '),
          prev: 'absolute z-10 bottom-0 left-0 rotate-180',
          next: 'absolute z-10 bottom-0 right-0',
        },
        fixedWidth: false,
        fixedHeight: 30,
        autoWidth: true,
        gap: 22,
        isNavigation: true,
        padding: { left: 16, right: 16 },
        pagination: false,
      })

      nav.on('active', onActiveChange.bind(this))
      window.addEventListener('resize', onResize.bind(this))

      main.sync(nav)
      main.mount()
      nav.mount()

      updateTextOverInSlides()
    },
    destroy() {
      nav.off('active', onActiveChange)
      window.removeEventListener('resize', onResize)
    },
  }
}
