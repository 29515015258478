export default () => ({
  video: null,
  started: false,
  init() {
    this.video = this.$el.querySelector('video')

    this.$watch('started', (newValue) => {
      if (newValue) {
        this.video.setAttribute('controls', true)
      }
    })
  },
  play() {
    this.started = true
    this.video.play()
  },
  pause() {
    this.video.pause()
  },
})
