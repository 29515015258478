import 'splitting/dist/splitting.css'
import Splitting from 'splitting'

/**
 * Splitting.js directive.
 * @see https://alpinejs.dev/advanced/extending#custom-directives
 * @see https://splitting.js.org/
 * usage by default:
 *   <div x-splitting>lorem ipsum</div>
 * usage using lines plugins with custom options:
 *   <div x-splitting:lines="{ whitespace: true }">lorem ipsum</div>
 */
export default (el, { value: by, expression }, { evaluate }) => {
  const options = expression ? evaluate(expression) : {}
  const splitting = Splitting({ target: el, by, ...options })
  // eslint-disable-next-line no-param-reassign
  el.$splitting = splitting.length === 1 ? splitting[0] : splitting
}
