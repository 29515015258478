import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'
import autosize from '@marcreichel/alpine-autosize'
import gsap from 'gsap'
import { mapKeys } from 'lodash-es'
import adaptiveIframeHeight from '~directives/adaptiveIframeHeight'
import splitting from '~directives/splitting'
import Accordion from '~components/Accordion'
import BreakpointsHelper from '~components/BreakpointsHelper'
import Hero from '~components/Hero'
import HeroHome from '~components/HeroHome'
import One from '~components/One'
import SiteHeader from '~components/SiteHeader'
import Slider from '~components/Slider'
import StickyCards from '~components/StickyCards'
import VideoPlayer from '~components/VideoPlayer'
import colors from '~/theme/colors.json'

const { teal, blue } = colors

export default () => {
  document.addEventListener('alpine:init', () => {
    Alpine.plugin(collapse)
    Alpine.plugin(intersect)
    Alpine.plugin(autosize)

    Alpine.store('ui', {
      hasAdminBar: false,
      adminBarDynamic: false,
      adminBarHeight: 0,
      headerHeight: 0,
      headerTransparent: false,
      init() {
        this.hasAdminBar = document.body.classList.contains('admin-bar')
      },
      setAdminBarDynamic(value) {
        this.adminBarDynamic = value
      },
      setAdminBarHeight(value) {
        this.adminBarHeight = value
      },
      setHeaderHeight(value) {
        this.headerHeight = value
      },
      setHeaderTransparent(value) {
        this.headerTransparent = value
      },
    })

    // Register directives
    Alpine.directive('splitting', splitting)
    Alpine.directive('adaptive-iframe-height', adaptiveIframeHeight)

    // Register components
    Alpine.data('accordion', Accordion)
    Alpine.data('breakpointsHelper', BreakpointsHelper)
    Alpine.data('hero', Hero)
    Alpine.data('heroHome', HeroHome)
    Alpine.data('one', One)
    Alpine.data('siteHeader', SiteHeader)
    Alpine.data('slider', Slider)
    Alpine.data('stickyCards', StickyCards)
    Alpine.data('videoPlayer', VideoPlayer)
  })

  window.Alpine = Alpine
  Alpine.start()

  gsap
    .timeline({
      scrollTrigger: {
        refreshPriority: -1,
        trigger: 'body',
        start: 'top top',
        end: 'bottom bottom',
        scrub: 2,
      },
    })
    .fromTo(
      'html',
      mapKeys(teal, (_, key) => `--accent-${key}`),
      { ...mapKeys(blue, (_, key) => `--accent-${key}`), ease: 'power2.in' }
      // For now, we only need the color 500
    )

  if (window.dataLayer) {
    Array.from(document.querySelectorAll('[data-event]')).forEach((el) => {
      el.addEventListener('click', () => {
        const { event } = el.dataset
        window.dataLayer.push({ event })
      })
    })
  }
}
