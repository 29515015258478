import gsap from 'gsap'

export default () => ({
  stepIndex: 0,
  currentAnim: null,
  async init() {
    // Wait for directive splitting to be loaded
    await this.$nextTick()

    const tl = gsap.timeline({
      scrollTrigger: {
        anticipatePin: true,
        refreshPriority: 1,
        trigger: this.$el,
        start: 'top top',
        end: 'bottom -30%',
        scrub: 1,
        ease: 'power3',
        pin: true,
        onUpdate: ({ progress }) => {
          if (progress < 0.4) {
            this.stepIndex = 0
          } else {
            this.stepIndex = 1
          }
        },
        onToggle: ({ isActive }) => {
          this.$store.ui.setHeaderTransparent(isActive)
        },
      },
    })

    tl.fromTo(
      this.$refs.background,
      { scale: 1.3 },
      { autoAlpha: 1, scale: 1, duration: 0.5 },
      0
    )

    // Watch for stepIndex changes
    this.$watch('stepIndex', this.changeStep.bind(this))

    // Set style by default
    gsap.set(this.$refs.step1, { autoAlpha: 1 })
    const hide = this.hideStep(1, { paused: true })
    hide.seek(hide.endTime())
  },
  changeStep(newValue, oldValue) {
    // If an animation is running, just reverse it
    if (this.currentAnim) {
      this.currentAnim.reverse()
    }
    // Let's create another animation
    else {
      const tl = gsap.timeline({
        onComplete: () => {
          this.currentAnim = null
        },
        onReverseComplete: () => {
          this.currentAnim = null
        },
      })
      tl.add(this.hideStep(oldValue)).add(this.showStep(newValue), '-=0.05')
      this.currentAnim = tl
    }
  },
  showStep(stepIndex, options = {}) {
    const step = stepIndex === 0 ? this.$refs.step0 : this.$refs.step1
    const tl = gsap.timeline({
      defaults: { ease: 'power3.out' },
      ...options,
    })
    // Make step visible
    tl.set(step, { autoAlpha: 1 })

    // Gather line elements
    const lines = []
    if (stepIndex === 0) {
      lines.push(...this.$refs.step0Title.$splitting.lines)
      lines.reverse()
    } else if (stepIndex === 1) {
      const { step1Title: title, step1Desc: desc } = this.$refs
      lines.push(...title.$splitting.lines)
      if (desc) {
        lines.push(desc)
      }
    }

    // Stagger lines
    lines.forEach((line, index) => {
      tl.to(line, { autoAlpha: 1, y: 0, duration: 0.7 }, index * 0.2)
    })
    return tl
  },
  hideStep(stepIndex, options = {}) {
    const step = stepIndex === 0 ? this.$refs.step0 : this.$refs.step1
    const tl = gsap.timeline({
      defaults: { ease: 'power3.in' },
      ...options,
    })

    // Make sure the step is visible
    tl.set(step, { autoAlpha: 1 })

    // Gather line elements
    const lines = []
    if (stepIndex === 0) {
      lines.push(...this.$refs.step0Title.$splitting.lines)
    } else if (stepIndex === 1) {
      const { step1Title: title, step1Desc: desc } = this.$refs
      lines.push(...title.$splitting.lines)
      if (desc) {
        lines.push(desc)
      }
      lines.reverse()
    }
    lines.forEach((line, index) => {
      tl.to(
        line,
        { autoAlpha: 0, y: stepIndex === 0 ? -50 : 50, duration: 0.7 },
        index * 0.2
      )
    })

    // Make step invisible
    tl.set(step, { autoAlpha: 0 })
    return tl
  },
})
